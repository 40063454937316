/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import { makeStyles } from '@material-ui/core'

import { joinStyles, palette } from 'app/utils/theme'
import { VariantHeading } from 'app/types/ui/heading'

const useStyles = makeStyles({
    root: {
        fontWeight: 'bold',
    },

    h1: {
        fontSize: '2.2rem',
    },

    h2: {
        fontSize: '1.5rem',
    },

    h3: {
        fontSize: '1.2rem',
    },

    center: {
        textAlign: 'center',
    },

    primary: {
        color: palette.primary.main,
    },

    secondary: {
        color: palette.secondary.main,
    },
})

export const Heading = ({
    className,
    variant = 'h1',
    color = 'primary',
    center,
    ...props
}) => {
    const classes = useStyles()

    return (
        <>
            {/* H1 */}
            {variant === VariantHeading.enum.h1 && (
                <h1
                    className={joinStyles(
                        classes.root,
                        classes[variant],
                        classes[color],
                        center && classes.center,
                        className,
                    )}
                    {...props}
                />
            )}

            {/* H2 */}
            {variant === VariantHeading.enum.h2 && (
                <h2
                    className={joinStyles(
                        classes.root,
                        classes[variant],
                        classes[color],
                        center && classes.center,
                        className,
                    )}
                    {...props}
                />
            )}

            {/* H3 */}
            {variant === VariantHeading.enum.h3 && (
                <h3
                    className={joinStyles(
                        classes.root,
                        classes[variant],
                        classes[color],
                        center && classes.center,
                        className,
                    )}
                    {...props}
                />
            )}
        </>
    )
}
