/** Day duration in milliseconds. */
export const DAY_MS = 1_000 * 60 * 60 * 24

/** Maximum interval range day between two dates. */
export const MAX_DAY_RANGE = 62

/**
 * Get a new date formatted.
 * @param {number=0} days Number of days to add or remove.
 * @param {string=} date Date to transform.
 * @return {string}
 */
export const newDateFormatted = (days = 0, date) => {
    const dateToUse = date ? new Date(date) : new Date()

    const [newDate] = new Date(dateToUse.setDate(dateToUse.getDate() + days))
        .toISOString()
        .split('T')

    return newDate
}

/** The default date to start the filter. */
export const DEFAULT_DATE_START = newDateFormatted(-7)

/** The default date to end the filter. */
export const DEFAULT_DATE_END = newDateFormatted()

/**
 * Transform date in readable locale date.
 * @param {string} date Date to transform
 * @param {string} [lang=en] i18n code
 * @param {Intl.DateTimeFormatOptions.dateStyle} [dateStyle=short] Style to apply
 * @return {string} Date formated wanted
 */
export const localeDate = (date, lang = 'en', dateStyle = 'short') => {
    return new Date(date).toLocaleDateString(lang, { dateStyle: dateStyle })
}

/**
 * Transform date in readable locale time.
 * @param {string} date Date to transform
 * @param {string} [lang=en] i18n code
 * @param {Intl.DateTimeFormatOptions.dateStyle} [timeStyle=short] Style to apply
 * @return {string} Time formated wanted
 */
export const localeTime = (date, lang = 'en', timeStyle = 'short') => {
    let dateFormatted = new Date(date)

    if (dateFormatted.toString() === 'Invalid Date') {
        const [hours, minutes, seconds] = date.split(':')

        dateFormatted = new Date()
        dateFormatted.setHours(hours)
        dateFormatted.setMinutes(minutes)
        dateFormatted.setSeconds(seconds)
    }

    return dateFormatted.toLocaleTimeString(lang, { timeStyle })
}

/**
 * Transform date in readable locale date and time.
 * @param {string} date Date to transform
 * @param {string} [lang=en] i18n code
 * @return {string} Date and time formated wanted
 */
export const localeDateAndTime = (date, lang = 'en') => {
    return new Date(date).toLocaleString(lang, { timeZoneName: 'short' })
}

/**
 * @param {Date|string|null} value
 * @return {Date|string|null}
 */
export const transformHour = (value) => {
    if (value || (!value) instanceof Date) {
        const date = new Date()
        date.setHours(+value.split(':')[0])
        date.setMinutes(+value.split(':')[1])
        return date
    }
    return value
}

/** Get the timezone of the user to return to the backend. */
export const getTimeZoneNumber = () => {
    const timeZoneCalculated = new Date().getTimezoneOffset() / 60
    const splitted = Math.abs(timeZoneCalculated).toString().split('.')

    const operator =
        timeZoneCalculated.toString().startsWith('-') ||
        timeZoneCalculated === 0
            ? '+'
            : '-'

    const hours = splitted[0].length === 2 ? splitted[0] : `0${splitted[0]}`
    const minutes = timeZoneCalculated.toString().endsWith('.5') ? '30' : '00'

    return `${operator}${hours}:${minutes}`
}

// To replace whith functions above
/**
 * @param {Date} date
 * @return {null|string}
 */
export const getTime = (date) => {
    return `${date.getHours()}:${date.getMinutes()}`
}

/**
 * Get the number of day difference between two dates.
 * @param {string} dateStart
 * @param {string} dateEnd
 * @return {number}
 */
export const getDayDifference = (dateStart, dateEnd) => {
    return Math.abs(
        Math.floor((new Date(dateStart) - new Date(dateEnd)) / DAY_MS),
    )
}
