import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allMicrowaves } from 'app/mocks/data/machines'
import { api } from 'app/utils/constants'

export const deleteMicrowave = http.delete(
    `${process.env.REACT_APP_API_ADDRESS}/${api.machines.microwaves.DETAILS}`,
    ({ params }) => {
        const { id } = params

        const microwaveFound = allMicrowaves.find(
            (microwave) => microwave.id.toString() === id,
        )

        // Not found
        if (!microwaveFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return new HttpResponse(null, {
            status: StatusCodes.NO_CONTENT,
        })
    },
)
