import { makeStyles } from '@material-ui/core'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
})

export const TableRowSummary = ({ className, ...props }) => {
    const classes = useStyles()

    return (
        <tr
            className={joinStyles(classes.root, className)}
            {...props}
        />
    )
}
