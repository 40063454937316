import { makeStyles } from '@material-ui/core'
import { joinStyles, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        overflow: 'auto',
        borderTopLeftRadius: various.borderRadius,
        borderTopRightRadius: various.borderRadius,
        '&:has(tfoot)': {
            borderBottomLeftRadius: various.borderRadius,
            borderBottomRightRadius: various.borderRadius,
        },
    },

    table: {
        width: '100%',
    },
})

export const Table = ({ className, ...props }) => {
    const classes = useStyles()

    return (
        <div className={joinStyles(classes.root, className)}>
            <table
                className={classes.table}
                {...props}
            />
        </div>
    )
}
