import { http, HttpResponse } from 'msw'

import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'
import { allMicrowaves } from 'app/mocks/data/machines'

export const getAllMicrowaves = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.machines.microwaves.LIST}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get('client__client__id__in')
        const clientIdsFilter = url.searchParams.get('client__id__in')
        const siteIdsFilter = url.searchParams.get('site__id__in')
        const microwaveIdsFilter = url.searchParams.get('id__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allMicrowavesFiltered = allMicrowaves

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managerIdsFilter)

            allMicrowavesFiltered = allMicrowavesFiltered.filter((microwave) =>
                managerIdsSplit.includes(
                    microwave.site.customer_name.client.id,
                ),
            )
        }

        // Client filter
        if (!!clientIdsFilter) {
            const clientIdsSplit = queryIntoArrayNumber(clientIdsFilter)

            allMicrowavesFiltered = allMicrowavesFiltered.filter((microwave) =>
                clientIdsSplit.includes(microwave.site.customer_name.id),
            )
        }

        // Site filter
        if (!!siteIdsFilter) {
            const siteIdsSplit = queryIntoArrayNumber(siteIdsFilter)

            allMicrowavesFiltered = allMicrowavesFiltered.filter((microwave) =>
                siteIdsSplit.includes(microwave.site.id),
            )
        }

        // Microwave ID filter
        if (!!microwaveIdsFilter) {
            const microwaveIdsSplit = queryIntoArrayNumber(microwaveIdsFilter)

            allMicrowavesFiltered = allMicrowavesFiltered.filter((microwave) =>
                microwaveIdsSplit.includes(microwave.id),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allMicrowavesFiltered = allMicrowavesFiltered.filter((microwave) =>
                microwave.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allMicrowavesFiltered.length > limit
                ? offset + limit
                : allMicrowavesFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allMicrowavesFiltered.slice(offset, limitArray)
                : allMicrowavesFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allMicrowavesFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allMicrowavesFiltered.length,
            next,
            results,
        })
    },
)
